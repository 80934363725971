<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-bordered">
            <thead class="theah-inverted">
              <th>{{ $t('player') }}</th>
              <th v-if="tournamentIsPremium">{{ $t('price') }}</th>
              <th>{{ $t('pendingRounds') }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(player, ind) in players" :key="ind">
                <td>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
                    <img :src="player.avatar" :alt="player.nick" class="img-icon mr-2">
                    {{ player.nick }}
                  </router-link>
                </td>
                <td v-if="tournamentIsPremium" class="text-right">{{ player.price | moneyFormat }}</td>
                <td class="text-right">{{ player.rounds }}</td>
                <td class="text-center">
                  <b-button variant="info" @click="openModalSeller(player)">
                    <i class="fa fa-shopping-cart"></i>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal ref="modalSeller" :title="$t('notice')">
      <h6>{{ $t('sellerPlayerConfirm') }}</h6>
      <h5>{{ selectedPlayer.nick }}</h5>
      <div slot="modal-footer" class="text-center w-100">
        <b-button variant="outline-primary" class="mx-2" @click="$refs.modalSeller.hide()">{{ $t('not') }}</b-button>
        <b-button ref="btnSeller" variant="primary" @click="seller">{{ $t('yes') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      players: [],
      tournamentIsPremium: false,
      selectedPlayer: {},
      team: {}
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'tournamentFront',
      'isAuthenticated'
    ])
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.go(-1)
      return;
    }
    this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'signings')
    this.fetchData()
  },
  methods: {
    seller() {
      this.$refs.btnSeller.blur()
      const params = {
        user_id: this.selectedPlayer.id,
        type: 'V',
        team_name: this.team.name,
        tournament_name: this.tournamentFront.tournament.name
      }
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const tournamentId = this.tournamentFront.tournament.id
      const teamId = this.team.id
      const path = `auth/${lang}/console/${console}/tournament/${tournamentId}/signings/team/${teamId}/sell-players`
      this.$axios.post(path, params).then(response => {
        this.$refs.modalSeller.hide()
        this.$toastr.success(response.data.message);
      }).catch(() => {
        this.$refs.modalSeller.hide()
      })
    },
    openModalSeller(player) {
      this.selectedPlayer = player
      this.$refs.modalSeller.show();
    },
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const tournamentSlug = this.$route.params.tournamentSlug
      const teamSlug = this.$route.params.teamSlug
      this.path = `auth/${lang}/console/${console}/tournament/${tournamentSlug}/signings/team/${teamSlug}/sell-players`
      this.$axios.get(this.path).then((response) => {
        const data = response.data
        this.players = data.players
        this.tournamentIsPremium = data.tournament_is_premium
        this.team = data.team
      })
    }
  }
}
</script>
